import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Paper } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonClose: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  image__container: {
    borderRadius: 0,
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '40vh',
    position: 'relative',
  },

  toolbar: {
    borderRadius: 0,
    display: 'flex',
    alignItems: 'flex-end',
    flexShrink: 0,
    minHeight: 48,
    padding: '0 4px',
    zIndex: theme.zIndex.appBar,
  },
}));

const ChatToolbar = ({
  children,
  className,
  preview,
  onClickDelete,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  return (
    <div className={classNames(classes.root, className)} {...other}>
      {preview && (
        <Paper elevation={2} className={classes.image__container}>
          <IconButton onClick={onClickDelete} className={classes.buttonClose}>
            <CloseIcon />
          </IconButton>
          {preview}
        </Paper>
      )}
      <Paper className={classNames(classes.toolbar, className)} square elevation={preview ? 0 : 2}>
        {children}
      </Paper>
    </div>
  );
};

ChatToolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClickDelete: PropTypes.func,
  preview: PropTypes.node,
};

ChatToolbar.defaultProps = {
  className: '',
  onClickDelete: () => console.log('onClickDelete missing'),
};

export default ChatToolbar;
