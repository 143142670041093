import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { isWidthDown, isWidthUp } from '@material-ui/core/withWidth';

import { IconButton, AppBar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useWidth } from '../Hooks';

import { menuButton } from '../styling';
import ScrollableContent from '../ScrollableContent';

import Flex from '../Flex';
import NavigationDrawer from './NavigationDrawer';
import NavItemGroup from './NavItemGroup';
import DefaultNavItem from './NavItem';
import Toolbar from '../Toolbar';

const useStyles = makeStyles(theme => {
  return {
    // Styles for mini variant Drawer:
    drawerPaper: {
      position: 'relative',
      width: theme.increments.tablet * 4,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
      zIndex: 'auto',
      borderRight: 'none',
    },
    drawerPhone: {
      position: 'fixed',
    },
    inner: {
      height: '100%',
    },
    drawerPaperClosed: {
      width: 72,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    drawerInner: {
      position: 'relative',
      height: '100%',
      // Make the items inside not wrap when transitioning:
      width: theme.increments.tablet * 4,
    },
    appBar: {
      boxShadow: 'none',
      backgroundColor: theme.palette.background.appBar,
    },
    menuButtonWrapper: {
      position: 'absolute',
      zIndex: theme.zIndex.appBar + 2,
      top: 0,
      left: 0,
      height: 64,
      width: 128,
      background: `linear-gradient(to right, rgba(${theme.palette.primary[200]},0.8), rgba(${theme.palette.primary[200]},0))`,
    },
    menuButtonWrapped: {
      marginTop: theme.spacing.unit,
      marginLeft: theme.spacing.unit * 1.5,
      color: theme.palette.common.white,
    },
    menuButton: {
      ...menuButton(theme),
    },
    mobile: {
      zIndex: theme.zIndex.drawer,
    },
    navItemGroup: {
      borderTop: 'none',
      paddingBottom: 0,
    },
    logoutButton: {
      marginLeft: 12,
    },
    navigation: {
      borderRight: `1px solid ${theme.palette.divider}`,
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    navigationDocked: {
      width: 72,
    },
    avatar: {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
    label: {
      whiteSpace: 'nowrap',
    },
    topToolbar: {},
    logoWrapper: {
      width: '100%',
      '& > *': { width: '100%' },
    },
    logoInline: {
      marginLeft: 48,
    },
    logoFullWidth: {
      position: 'absolute',
      left: 0,
      top: 0,
    },
    listItemText: {
      whiteSpace: 'nowrap',
    },
    listItemIcon: {},
    listItem: {
      paddingBottom: theme.spacing.unit * 2,
      paddingTop: theme.spacing.unit * 2,
    },
    logout: {
      alignSelf: 'flex-end',
    },
    spacer: {
      flexGrow: 1,
    },
  };
});

const ResponsiveNavigationDrawer = ({
  WrapperComponent,
  navItemComponent: NavItem,
  children,
  open,
  onDrawerRequestClose,
  onDrawerRequestOpen,
  onOpenLink,
  navGroups,
  logo,
  logoFullWidth,
  onClickLogout,
  logoutLabel,
  classes: eClasses,
  ...other
}) => {
  const classes = useStyles({ classes: eClasses });
  const width = useWidth();
  const isPhone = isWidthDown('xs', width);
  const isWide = isWidthUp('lg', width);
  const Wrapped = (
    <AppBar position="static" className={classes.appBar}>
      <Toolbar className={classes.topToolbar}>
        {!isWide && (
          <IconButton
            onClick={open ? onDrawerRequestClose : onDrawerRequestOpen}
            className={classes.menuButton}
          >
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        )}
        {!!logo && (
          <div
            className={classNames(
              classes.logoWrapper,
              !isWide && classes.logoInline,
              isWide && logoFullWidth && classes.logoFullWidth,
            )}
          >
            {logo}
          </div>
        )}
      </Toolbar>
      {children}
    </AppBar>
  );
  return (
    <Fragment>
      {isPhone && !open && (
        <div className={classes.menuButtonWrapper}>
          <IconButton
            onClick={onDrawerRequestOpen}
            className={classNames(classes.menuButtonWrapped, classes.mobile)}
          >
            <MenuIcon />
          </IconButton>
        </div>
      )}
      <NavigationDrawer
        variant={isPhone ? 'temporary' : 'permanent'}
        open={isWide || open}
        onClose={onDrawerRequestClose}
        classes={{
          paper: classNames(
            classes.drawerPaper,
            isPhone && classes.drawerPhone,
            !open && !isWide && classes.drawerPaperClosed,
          ),
          inner: classNames(classes.inner),
        }}
        {...other}
      >
        <Flex fullColumn className={classes.drawerInner}>
          {WrapperComponent ? <WrapperComponent>{Wrapped}</WrapperComponent> : Wrapped}
          <div
            className={classNames(classes.navigation, !open && !isWide && classes.navigationDocked)}
          >
            <ScrollableContent classes={{ content: classes.content }}>
              {navGroups.map(({ subheader, links }, index) => (
                <NavItemGroup key={index} subheader={subheader} className={classes.navItemGroup}>
                  {links.map(({ label, icon, to, key }) => (
                    <NavItem
                      key={key || label}
                      icon={icon}
                      label={label}
                      to={to}
                      onClick={event => {
                        if (isPhone) {
                          onDrawerRequestClose(event);
                        }
                        onOpenLink ? onOpenLink(to) : null;
                      }}
                      classes={{ listItemText: classes.label }}
                    />
                  ))}
                </NavItemGroup>
              ))}
            </ScrollableContent>
            <div className={classes.spacer} />
            {onClickLogout && (
              <NavItem
                key="logout"
                icon={<LogoutIcon />}
                to="#"
                label={logoutLabel}
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  onClickLogout(event);
                }}
                classes={{
                  root: classes.logout,
                  listItem: classes.listItem,
                  listItemText: classes.listItemText,
                  listItemIcon: classes.listItemIcon,
                }}
              />
            )}
          </div>
        </Flex>
      </NavigationDrawer>
    </Fragment>
  );
};

ResponsiveNavigationDrawer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  logo: PropTypes.node,
  logoFullWidth: PropTypes.bool,
  logoutLabel: PropTypes.string,
  navGroups: PropTypes.array.isRequired,
  navItemComponent: PropTypes.node,
  onClickLogout: PropTypes.func,
  onDrawerRequestClose: PropTypes.func.isRequired,
  onDrawerRequestOpen: PropTypes.func.isRequired,
  onOpenLink: PropTypes.func,
  open: PropTypes.bool.isRequired,
  WrapperComponent: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
};

ResponsiveNavigationDrawer.defaultProps = {
  logoFullWidth: false,
  logoutLabel: 'Afmelden',
  navItemComponent: DefaultNavItem,
};

export default ResponsiveNavigationDrawer;
