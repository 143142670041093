import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, AppBar } from '@material-ui/core';
import { isWidthDown } from '@material-ui/core/withWidth';

import { Flex, ScrollableContent, Toolbar, Scrim, useWidth } from '../index';
import { useState } from 'react';

const COVER_HEIGHT = 271;
const APPBAR_HEIGHT = 64;
const APPBARL_HEIGHT = 144;

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.paper,
    position: 'relative',
  },
  appbarBack: {
    position: 'relative',
    backgroundColor: theme.palette.primary[800],
    height: APPBAR_HEIGHT,
    zIndex: 10,
    width: '100%',
    boxSizing: 'border-box',
    dropshadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -4px, rgba(0, 0, 0, 0.14) 0px 4px 5px -5px, rgba(0, 0, 0, 0.12) 0px 1px 10px -10px',
  },
  appbarToolbar: {
    paddingLeft: 72,
  },
  hideAppbar: {
    opacity: 0,
  },
  topToolbarWithCover: {
    backgroundColor: 'transparent',
  },
  scrollContainer: {},
  cover: {
    minHeight: COVER_HEIGHT,
    position: 'absolute',
    backgroundColor: theme.palette.primary[800],
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 12,
    pointerEvents: 'none',
  },
  content: {
    paddingTop: COVER_HEIGHT,
    zIndex: 11,
  },
  scrim: {
    zIndex: '-1 !important',
  },
  headerContent: {
    paddingTop: APPBAR_HEIGHT,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    pointerEvents: 'auto',
  },
  spacer: {
    height: 0,
    margin: 0,
    padding: 0,
  },
  headerWrapper: {
    padding: 24,
    paddingTop: 0,
    paddingBottom: 16,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    alignContent: 'flex-end',
    justifyContent: 'space-between',
  },
  title: {
    fontSize: '2.2em',
    lineHeight: '1.2em',
  },
  titleWithCover: {
    color: 'white',
    fontSize: '1.4em',
    lineHeight: '1.2em',
    marginBottom: 8,
  },
  appBarTitle: {
    fontSize: '1.4em',
    lineHeight: '1.2em',
    margin: 0,
    fontWeight: 600,
  },
}));

const CoverView = ({
  children,
  action,
  header,
  title,
  cover,
  themeProviderComponent: ThemeProvider,
  TitleProps,
  ...other
}) => {
  const classes = useStyles();
  const width = useWidth();
  const isPhone = isWidthDown('xs', width);
  const [offset, setOffset] = useState(0);
  const hideAppbar = offset < COVER_HEIGHT - APPBAR_HEIGHT;
  const hideHeader = offset >= COVER_HEIGHT - APPBARL_HEIGHT;
  return (
    <Flex fullColumn classes={{ root: classes.root }} {...other}>
      {isPhone ? (
        <AppBar
          size="default"
          className={classNames(classes.appbarBack, hideAppbar && classes.hideAppbar)}
        >
          <Toolbar className={classNames(classes.appbarToolbar)}>
            <Typography className={classes.title && classes.appBarTitle}>{title}</Typography>
          </Toolbar>
        </AppBar>
      ) : (
        ''
      )}
      <AppBar
        style={{ top: -offset }}
        classes={{
          root: classNames(classes.cover),
        }}
      >
        {cover && <Scrim cover={cover} classes={{ root: classes.scrim }} />}
        <div className={classes.headerContent}>
          <div className={classes.headerWrapper}>
            {action ? <div className={classes.actions}>{action}</div> : ''}
            <div className={classes.spacer} />
            {!hideHeader && header}
            <Typography
              color="white"
              rows="1"
              margin="normal"
              className={classNames(classes.title, cover && classes.titleWithCover)}
              {...TitleProps}
            >
              {title}
            </Typography>
          </div>
        </div>
      </AppBar>
      <ScrollableContent
        classes={{ root: classes.scrollContainer }}
        onUpdate={({ scrollTop }) =>
          setOffset(Math.min(scrollTop, isPhone ? COVER_HEIGHT : COVER_HEIGHT - APPBARL_HEIGHT))
        }
      >
        <div className={classes.content}>{children}</div>
      </ScrollableContent>
    </Flex>
  );
};

CoverView.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node,
  cover: PropTypes.string,
  header: PropTypes.node,
  themeProviderComponent: PropTypes.node,
};

export default CoverView;
