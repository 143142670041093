import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  IconButton,
  Badge,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
} from '@material-ui/core';

import FileIcon from '@material-ui/icons/InsertDriveFile';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import HiddenIcon from '@material-ui/icons/VisibilityOff';

import { isOfTypeImage, getResizedImage } from './helpers';

const useStyles = makeStyles(theme => ({
  fileListItemText: {
    maxWidth: '80%',
    padding: '0 16px',
  },
  thumbnail: {
    width: 40,
    height: 40,
  },
  fileIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 40,
    height: 40,
    margin: 0,
    color: theme.palette.icon,
  },
  badge: {
    top: 22,
    left: -6,
    right: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  badgeIcon: {
    width: 20,
    height: 20,
    color: theme.palette.icon,
  },
}));

const FileListItem = ({
  label,
  fileUrl,
  fileType,
  description,
  interactive,
  isImage,
  onClickDownloadFile,
  onClickOpen,
  translations,
  hidden,
  classes: eClasses,
}) => {
  const classes = useStyles({ classes: eClasses });
  const t = {
    download: 'download',
    ...translations,
  };

  const fileName = fileUrl && fileUrl.substring(fileUrl.lastIndexOf('/') + 1);

  const secondaryText = description || fileName;

  let thumbnail = (
    <ListItemIcon className={classes.fileIcon}>
      <FileIcon />
    </ListItemIcon>
  );
  if (isImage || isOfTypeImage(fileType || fileName)) {
    thumbnail = (
      <img
        src={getResizedImage(fileUrl, 'w=40&h=40&t=square')}
        alt={fileName}
        className={classes.thumbnail}
      />
    );
  }

  if (hidden) {
    thumbnail = (
      <Badge
        classes={{ badge: classes.badge }}
        badgeContent={<HiddenIcon className={classes.badgeIcon} />}
      >
        {thumbnail}
      </Badge>
    );
  }

  return (
    <ListItem button={interactive} dense onClick={onClickOpen} data-file-url={fileUrl}>
      {thumbnail}
      <div className={classes.fileListItemText}>
        <Typography variant="subtitle1" noWrap title={fileName}>
          {label}
        </Typography>
        <Typography variant="body1" color="textSecondary" noWrap>
          {secondaryText}
        </Typography>
      </div>
      <ListItemSecondaryAction className={classes.fileListItemAction}>
        <IconButton onClick={onClickDownloadFile} data-url={fileUrl} title={t.download}>
          <DownloadIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

FileListItem.propTypes = {
  description: PropTypes.string,
  fileType: PropTypes.string,
  fileUrl: PropTypes.string.isRequired,
  hidden: PropTypes.bool,
  interactive: PropTypes.bool,
  isImage: PropTypes.bool,
  label: PropTypes.string.isRequired,
  onClickDownloadFile: PropTypes.func,
  onClickOpen: PropTypes.func,
  translations: PropTypes.object,
};

FileListItem.defaultProps = {
  description: null,
  interactive: false,
  hidden: false,
  isImage: false,
  onClickDownloadFile: null,
  onClickOpen: null,
  translations: {},
};

export default FileListItem;
