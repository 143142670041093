import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

// NOTE: Does not handle multiple menu's in the same component yet, but it probably shouldn't
export default compose(
  withState('menu', 'updateMenu', { open: false, anchorEl: null }),
  withHandlers({
    handleMenuClick: ({ updateMenu }) => (event) => {
      updateMenu({ open: true, anchorEl: event.currentTarget });
    },
    handleMenuRequestClose: ({ updateMenu, menu }) => () => {
      updateMenu({ ...menu, open: false });
    },
  }),
);
