export const menuButton = theme => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1) * 1.5,
  zIndex: theme.zIndex.appBar + 2,
  color: theme.palette.common.white,
});

export const coverButton = theme => ({
  color: theme.palette.common.white,
  backgroundColor: 'rgba(0,0,0,0.08)',
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,0.12)',
  },
});
